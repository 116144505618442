function scrollToo ($e, duration, y) {
    y = y || 0;
    duration = duration || 300;
    var _top = $e.offset().top + y;
    $('html,body').animate({"scrollTop" : _top}, duration);
    return false;
}

function placeholder () {
    let fields = $('.forma__field .forma__field__itext, .forma__field textarea');
    let selectors = $('.forma__field .forma__field__select');

    fields.each(function() {
        if ($(this).val() != '' && $(this).val() != $(this).parent().find('label').text()) $(this).parent().find('label').hide();
    });

    fields.on('keyup', function () {
        if ($(this).val() != '' && $(this).val() != $(this).parent().find('label').text()) $(this).parent().find('label').hide();
        else $(this).parent().find('label').show();
    }).on('focusin', function () {
        $(this).parent().addClass('forma__field_light');
    }).on('focusout', function () {
        $(this).parent().removeClass('forma__field_light');
    });

    selectors.on('focusin', function () {
        $(this).parent().addClass('forma__field_light');
    }).on('focusout', function () {
        $(this).parent().removeClass('forma__field_light');
    });
}

function search () {
    let icon = $('.head__search .head__search__icon');
    let forma = $('.head__search .head__search__forma');
    let but = $('.head__search__forma .button');
    let field = $('.head__search__forma input');

    icon.on('click', function() {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            forma.slideUp(300, 'linear');
        } else if (!$(this).next().is('.head__search__forma:animated')) {
            $(this).addClass('active');
            forma.slideDown(300, 'linear');
        }
    });

    but.on('click', function(e) {
        if (field.val() != '' && field.val() != field.prev().text()) $(this).parents('.forma').trigger('submit');
        e.preventDefault();
    });
}

/*********************************
********* + Menu mobile **********
*********************************/

function menuToggle () {
    var menu = $('#mm');
    var toggleBut = $('.menuToggle');
    var plusBut = $('#mm .mm__parent');

    toggleBut.on('click', function () {
        if (menu.css('display') == 'block') {
            //menu.animate({'opacity': 0}, 300, function() { $(this).hide(); });
            menu.slideUp(300, 'linear');
            toggleBut.removeClass('active');
        }
        else {
            menu.slideDown(300, 'linear');
            menu.show().animate({'opacity': 1}, 300);
            toggleBut.addClass('active');
        }
    });

    plusBut.on('click', function () {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).next('.subs').slideUp(300, 'linear');
        } else if (!$(this).next().is('.subs:animated')) {
            $(this).addClass('active');
            $(this).next('.subs').slideDown(300, 'linear');
        }
    });

    $(window).on('resize', function() {
        setTimeout(function() {
            if (window.innerWidth >= 769) {
                menu.show();
                menu.find('.active').each(function() {
                    $(this).removeClass('active');
                    $(this).next().css('display', 'block');
                })
            } else if (!toggleBut.hasClass('active')) menu.hide();
        }, 50);
    });

    $(document).on('click touchstart', function(event) {
		var $target = $(event.target);
        if (!$target.is(menu) && !menu.find($target).length && !$target.is(toggleBut) && !toggleBut.find($target).length && toggleBut.hasClass('active')) toggleBut.trigger('click');
    });
}

function scrollToForm () {
    var button = $('.head__right .button');
    var forma = $('.request').prev();

    button.on('click touchstart', function(event) {
        scrollToo(forma, 400, -30);
        event.preventDefault();
    })
}

function carousel () {
	$('.carouselProd__box').each(function() {
        $(this).owlCarousel({
            loop:false,
            margin:80,
            responsiveClass:true,
            nav:true,
            responsive:{
                0:{
                    items:1
                },
                400:{
                    items:2
                },
                600:{
                    items:3
                },
                768:{
                    items:4
                },
                1000:{
                    items:5
                }
            }
        });
    });
}

function slider () {
    var owl = $('.slider__box');
    owl.owlCarousel({
        items:1,
        loop:true,
        margin:0,
        autoplay:true,
        nav:true,
        autoplayTimeout:8000,
        autoplayHoverPause:true
    });
}

function catalogItemGallery () {
    $('body').on('click', '.catalog__item__images__gal a', function(event) {
        if (!$(this).hasClass('sel')) {
            $(this).parent().find('.sel').removeClass('sel');
            $(this).addClass('sel').parents('.catalog__item__images').find('.catalog__item__images__large').attr('src', $(this).attr('href'));;
        }
        event.preventDefault();
    });
}

function accordion () {
    var container = $('.catalog__item__description');
    var but = $('.catalog__item__description h4 span');

    but.on('click', function () {
        if ($(this).parent().hasClass('active')) {
            $(this).parent().removeClass('active').next().slideUp(300, 'linear');
        } else if (!$(this).parent().next().is('div:animated')) {
            container.find('.active').removeClass('active').next().slideUp(300, 'linear');
            $(this).parent().addClass('active').next().slideDown(300, 'linear');
        }
    });

    //if (container.find('[data-active="1"]').length) container.find('[data-active="1"] a').click();
}

function sendMsg () {
    var popup = $('.fixPopupMsg');
    var msg = $('.forma__sendMsg');
    var closeBut = $('.fixPopupMsg__close');
    
    var timeout;
    if (msg.find('div').length) {
        var classMsg = msg.find('div').attr('class');
        var textMsg = msg.find('div').text();
        popup.prepend('<div class="'+classMsg+'">'+textMsg+'</div>');
        popup.addClass('vis');
        timeout = setTimeout(function() {
            popup.animate({opacity : 'hide'}, 300, function() {
                $(this).removeClass('vis');
            });
        }, 5000);
        //popup.prepend('div class="'+classMsg+'"></div>')
    }

    closeBut.on('click', function() {
        clearTimeout(timeout);
        popup.animate({opacity : 'hide'}, 300, function() {
            $(this).removeClass('vis');
        });
    });
}

/********************************
********** Form Valid ***********
********************************/

/********************************
********** Form Valid ***********
********************************/

var CheckForms = function (form, button, options) {
    this.form = form;
    this.button = form.find(button);

    this.options = $.extend({}, CheckForms.Defaults, options);

    if (this.options.radio) this.radioChange();

    this.checkEmpty();
    this.submitForm();
    this.removeError();
}

CheckForms.Defaults = {
    errorClass: 'errorField',
    emptyClass: 'emptyField',
    radio: 0,
}

CheckForms.prototype.checkEmpty = function () {
    this.form.find('.requiredField').each(function () {
        if ($(this).val() == '' || $(this).val() == $(this).parent().find('label').text() || ($(this).is('[type = checkbox') && !$(this).is('input:checked'))) $(this).addClass('emptyField');
    });

    this.checkAllElements();
}

CheckForms.prototype.checkCorrect = function () {
    var patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    var patternDate = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
    this.form.find('.requiredField').each(function () {
        var item = $(this);
        var itemValue = item.val();
        var mailChecked = item.data('validate') != 'email' ? true : patternEmail.test(itemValue);
        var dateChecked = item.data('validate') != 'date' ? true : patternDate.test(itemValue);

        /**
         * @TODO:
         * 1. Get field type
         * 2. Check field by correct method (checkInput, checkRadio, checkCheckbox, checkEmail, checkDate)
         * 
         * checkDate by data-validate="date" + data-validatePattern="yyyy.mm.dd". Example: yyyy.mm.dd || dd.mm.yyyy
         **/ 
        if (!itemValue || !mailChecked || !dateChecked || itemValue == item.parent().find('label').text() || (item.is('[type = checkbox') && !item.is('input:checked'))) {
            item.addClass('errorField');
        }
        //if (item.is('[type = checkbox') && item.is('input:checked')) item.addClass('errorField');
    });

    this.checkAllElements();
}

CheckForms.prototype.removeError = function () {
    var patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    var patternDate = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
    //return patternEmail.test(value);

    this.form.on('keyup change', '.requiredField', function () {
        var item = $(this);
        var itemValue = item.val();
        var mailChecked = item.data('validate') != 'email' ? true : patternEmail.test(itemValue);
        var dateChecked = item.data('validate') != 'date' ? true : patternDate.test(itemValue);

        if (itemValue && itemValue != item.parent().find('label').text() && mailChecked && dateChecked) {
            item.removeClass('errorField emptyField');
        } else {
            itemValue == item.parent().find('label').text() ? item.addClass('emptyField') : item.addClass('errorField');
            if (!dateChecked) item.addClass('errorField');
        }

        if (item.is('[type = checkbox')) {
            item.is('input:checked') ? item.removeClass('errorField emptyField') : item.addClass('errorField');
        }

        var form = item.parents('form');
        var submitButton = form.find('.button_submit');
        if (!form.find('.emptyField').length && !form.find('.errorField').length) {
            submitButton.removeClass('button_disabled');
        } else {
            submitButton.addClass('button_disabled');
        }
    });
}

CheckForms.prototype.checkEmail = function (value) {
    var patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    return patternEmail.test(value);
}

CheckForms.prototype.checkAllElements = function () {
    if (!this.form.find('.emptyField').length && !this.form.find('.errorField').length) {
        this.button.removeClass('button_disabled');
    } else {
        this.button.addClass('button_disabled');
    }
}

CheckForms.prototype.submitForm = function () {
    this.button.click(function (event) {
        //this.checkEmpty();
        this.checkCorrect();
        event.preventDefault();
        if (!this.form.find('.emptyField').length && !this.form.find('.errorField').length) {
            if (this.button.parent().find('.forma__field__loader').length) this.button.parent().addClass('forma__field_loading');
            this.form.submit();
        }
    }.bind(this));
}

function popupBookForm() {
    $('.popupBookForm').magnificPopup({
		type: 'inline',
		preloader: false,
		focus: '#name',

		// When elemened is focused, some mobile browsers in some cases zoom in
		// It looks not nice, so we disable it:
		callbacks: {
			beforeOpen: function() {
				if($(window).width() < 700) {
					this.st.focus = false;
				} else {
					this.st.focus = '#name';
				}
			},
            elementParse: function(item) {
              // Function will fire for each target element
              // "item.el" is a target DOM element (if present)
              // "item.src" is a source that you may modify
          
              //console.log('Parsing content. Item object that is being parsed:', item);
              console.log(item.el[0].id)
            },
		}
    });

    // $('.popupBookForm').on('mfpOpen', function(e /*, params */) {
    //     var btn = $(e.target);
    //     let id = e.target.id;
    //     console.log(e.target)
    //     //console.log('Popup opened',  $.magnificPopup.instance);
    // });
}

var onlineBooking = function() {
    onlineBooking.$container = $('.forma_popup__inside');
    onlineBooking.$step = $('.forma__step');
    onlineBooking.$radioBox = $('.forma__field__flex_type');
    onlineBooking.$radio = $('.forma__field__flex_type .forma__field__radio');
    onlineBooking.$checkServ = $('#listserv .forma__field__checkbox input');
    onlineBooking.$fieldSelect = $('#listserv .forma__field__select__text');
    onlineBooking.$checkoptions = $('#listserv .forma__field__select__options input');
    onlineBooking.$services = $('#listserv');
    onlineBooking.$amount = $('.forma__field_amount span');
    onlineBooking.$dateInput = $('#datetimepicker');
    onlineBooking.$timeSelect = $('#time');

    onlineBooking.$srcPrice = $('meta[name="services-price-url"]').attr('content');
    onlineBooking.currency = $('meta[name="currency-name"]').attr('content');

    onlineBooking.price = 0;
    onlineBooking.type = 1;
    onlineBooking.serviceId = 0;
    onlineBooking.totalSum = 0;
    onlineBooking.checkedServiceCount = 0;
    
    onlineBooking.pull(onlineBooking.$srcPrice, 'GET');
    onlineBooking.popupBookForm();
    onlineBooking.accordion();
    onlineBooking.openBlock();
    onlineBooking.chooseType();
    onlineBooking.chooseServ();
    onlineBooking.timeSelectCheck();
    if ($('#datetimepicker').length) onlineBooking.execDate();
    // onlineBooking.pullTime();
    
    onlineBooking.$services.find('select, .forma__field__select__options input').each(function() {
        $(this).attr('disabled', 1);
    })
    new CheckForms($('#bookForm'), '.button_submit');
}

/** steps show/hide **/
onlineBooking.accordion = function() {

    onlineBooking.$step.on('click', function () {
        if (!$(this).hasClass('forma__step_disabled') && $(this).next().hasClass('active')) {
            $(this).next().removeClass('active').slideUp(300, 'linear');
        } else if (!$(this).hasClass('forma__step_disabled') && !$(this).next().is('div:animated')) {
            onlineBooking.$container.find('.active').removeClass('active').slideUp(300, 'linear');
            $(this).next().addClass('active').slideDown(300, 'linear');
        }
    });

    onlineBooking.$container.find('[data-step = 1]').trigger('click');
}

/** test step3 **/
onlineBooking.enableStepForm = function() {
    if (onlineBooking.$services.find('.vis').length && onlineBooking.$radioBox.find('.sel').length && onlineBooking.totalSum) onlineBooking.$container.find('[data-step = 3]').removeClass('forma__step_disabled');
    else onlineBooking.$container.find('[data-step = 3]').addClass('forma__step_disabled');
}

/** filling in the fields with prices after choosing a car category **/
onlineBooking.updatePrice = function() {

    for (let key in onlineBooking.price) {
        let box = onlineBooking.$services.find('#'+key);
        let list = onlineBooking.price[key][onlineBooking.type];
        for (let serv in list) {
            let option = box.find('[data-id='+serv+']');
            if (box.is('select')) option.val(option.data('title')+' – '+list[serv]).text(option.data('title')+' – '+list[serv]+' '+onlineBooking.currency).attr('data-price', list[serv]);
            else {
                option.find('input').val(option.data('title')+' – '+list[serv]).attr('data-price', list[serv]);
                option.find('span').text(option.data('title')+' – '+list[serv]+' '+onlineBooking.currency);
            }
        }
    }
}

/** calc sum of selected options in one selected service **/
onlineBooking.calcCheck = function(list) {
    let sum = 0;
    list.find('input:checked').each(function() {
        sum += parseInt($(this).data('price'));
    });
    return sum;
}

/** calc total sum **/
onlineBooking.calcTotal = function() {
    let sum = 0;
    onlineBooking.$services.find('.forma__field__checkbox').each(function() {
        if ($(this).find('input:checked').length) {
            if ($(this).next().find('select').length) sum += parseInt($(this).next().find('select').data('price'));
            else sum += onlineBooking.calcCheck($(this).next().find('.forma__field__select__options'));
        }
    });

    onlineBooking.$amount.text(numberFormat(sum, {decimals: 0, thousands_sep: ' '}) +' '+ onlineBooking.currency);

    onlineBooking.totalSum = sum;
}

/** filling forma__field__select__text of one selected service **/
onlineBooking.updateFieldOptions = function(list) {
    let count = list.find('input:checked').length;
    if (!count) list.prev().text(list.prev().data('default'));
    else {
        // let str = count == 1 ? list.find('input:checked').parent().data('title') +' – '  + list.find('input:checked').val() + onlineBooking.currency : list.find('input:checked').first().parent().data('title') + '... – ' + onlineBooking.calcCheck(list) + ' ' + onlineBooking.currency;
        let str = count == 1 ? list.find('input:checked').data('price') + ' ' + onlineBooking.currency : onlineBooking.calcCheck(list) + ' ' + onlineBooking.currency;
        list.prev().text(str);
    }
}

/** choose of services **/
onlineBooking.chooseServ = function() {
    onlineBooking.$services.find('select').on('change', function() {
        $(this).data('price', $(this).val().split('–')[1].trimStart());
        onlineBooking.calcTotal();
    });

    onlineBooking.$checkoptions.on('change', function() {
        let list = $(this).parents('.forma__field__select__options');
        onlineBooking.updateFieldOptions(list);
        onlineBooking.calcTotal();
        onlineBooking.enableStepForm();
        onlineBooking.dateTimeReset();
    });
}

/** open select or options of selected service **/
onlineBooking.openBlock = function() {
    onlineBooking.$checkServ.on('change', function() {
        let field = $(this).parent().next();
        if (field.hasClass('vis')) {
            field.removeClass('vis');
            if (field.find('select').length) field.find('select').attr('disabled', 1).attr('data-price', 0);
            else {
                field.find('.forma__field__select__options input').each(function() {
                    $(this).attr('disabled', 1);
                });
            }
        } else {
            field.addClass('vis');
            if (field.find('select').length) field.find('select').removeAttr('disabled').attr('data-price', field.find('select').val().split('–')[1].trimStart());
            else {
                field.find('.forma__field__select__options input').each(function() {
                    $(this).removeAttr('disabled');
                });
            }
        }
        if (onlineBooking.$amount.parent().css('visibility') == 'hidden') onlineBooking.$amount.parent().css('visibility', 'visible');
        onlineBooking.calcTotal();
        onlineBooking.enableStepForm();
        onlineBooking.dateTimeReset();
    });

    onlineBooking.$fieldSelect.on('click', function() {
        let field = $(this).parent();
        if (!field.hasClass('open')) {
            onlineBooking.$services.find('.open').removeClass('open');
            field.addClass('open');
        } else field.removeClass('open');

        //field.hasClass('open') ? field.removeClass('open') : field.addClass('open');
    });

    $(document).on('click', function(event) {
        let $target = $(event.target);
        if (!$target.is('.forma__field__select__options') && !$('.forma__field__select__options').find($target).length && !($target.is('.forma__field__select__text'))) onlineBooking.$services.find('.open .forma__field__select__text').trigger('click');

        // if($target.is('.forma__field__select__text') && !$target.parent().hasClass('open')) console.log(1)
    });
}

/** if bookform was opened from selected service check this service on form */
onlineBooking.markSelectedServ = function () {
    if (onlineBooking.serviceId && !onlineBooking.$services.find('[data-id = '+ onlineBooking.serviceId +']').is('input:checked')) onlineBooking.$services.find('[data-id = '+ onlineBooking.serviceId +']').trigger('click');
}

/** choose car's category */
onlineBooking.chooseType = function() {
    onlineBooking.$radio.on('change', function() {
        if (!$(this).is('input:checked')) {
            onlineBooking.$radioBox.find('.sel').removeClass('sel');
            $(this).addClass('sel');
            onlineBooking.type = $(this).data('id');
            onlineBooking.updatePrice();
            onlineBooking.$services.find('.forma__field__select__options').each(function() {
                onlineBooking.updateFieldOptions($(this));
            });
            onlineBooking.calcTotal();
        }
        if (onlineBooking.$container.find('[data-step = 2]').hasClass('forma__step_disabled')) onlineBooking.$container.find('[data-step = 2]').removeClass('forma__step_disabled').trigger('click');
        onlineBooking.enableStepForm();
        // if (onlineBooking.serviceId && !onlineBooking.$services.find('[data-id = '+ onlineBooking.serviceId +']').is('input:checked')) onlineBooking.$services.find('[data-id = '+ onlineBooking.serviceId +']').trigger('click');
    });
}

/** date and time fields reset */

onlineBooking.dateTimeReset = function() {
    onlineBooking.$dateInput.val('');
    onlineBooking.$timeSelect.empty().parent().addClass('forma__field_hide').find('.forma__field__title').hide();
}

/** time select's event */
onlineBooking.timeSelectCheck = function() {
    onlineBooking.$timeSelect.on('change', function() {
        $(this).val() != '' ? $(this).prev().hide() : $(this).prev().show();
    });
}

/** time select options */
onlineBooking.constructTimeSelect = function(options) {
    onlineBooking.$timeSelect.html('<option value=""></option>').prev().show();
    for (let i = 0; i < options.length; i++) {
        onlineBooking.$timeSelect.append('<option val="'+options[i]+'">'+options[i]+'</option>');
    }
}

/** pull of allow's time */
onlineBooking.pullTime = function() {
    // selects
    let checkedServiceCount = $('.counted__checkbox > input[type=checkbox]:checked').length;
    // inner checkboxes
    let subCheckedServiceCount = $('.forma__field__select__options input[type=checkbox]:checked').length;
    
    if (onlineBooking.$dateInput.val() != '') {
        onlineBooking.$dateInput.prev().hide();
        onlineBooking.$timeSelect.parent().addClass('forma__field_loading forma__field_hide');
        $.ajax({
            url: $('meta[name="services-time-url"]').attr('content'),
            method: 'GET',
            data: {'selectedServiceCount' : (checkedServiceCount + subCheckedServiceCount), 'date': onlineBooking.$dateInput.val()},
            dataType: 'json',
            success: function (data) {
                onlineBooking.constructTimeSelect(data);
                //onlineBooking.constructTimeSelect(JSON.parse("[" + data + "]"));
                onlineBooking.$timeSelect.parent().removeClass('forma__field_loading forma__field_hide');

                // onlineBooking.price = JSON.parse(data);
                // onlineBooking.updatePrice();
            }
        });
    }
// onlineBooking.$dateInput.on('change', function() {
    
    // });
}

/** exec datetimepicker */
onlineBooking.execDate = function() {
    $.datetimepicker.setLocale('ru');
    onlineBooking.$dateInput.datetimepicker(datePickerOptions);
}

/** pull price */
onlineBooking.pull = function(url, method) {
    $.ajax({
        url: url,
        method: method,
        //data: formData,
        // dataType: 'json',
        success: function (data) {
            onlineBooking.price = JSON.parse(data);
            onlineBooking.updatePrice();
        }
    });
}

onlineBooking.popupBookForm = function() {
    $('.popupBookForm').magnificPopup({
		type: 'inline',
		preloader: false,
		focus: '#name',

		// When elemened is focused, some mobile browsers in some cases zoom in
		// It looks not nice, so we disable it:
		callbacks: {
			beforeOpen: function() {
				if($(window).width() < 700) {
					this.st.focus = false;
				} else {
					this.st.focus = '#name';
				}
			},
            elementParse: function(item) {
              // Function will fire for each target element
              // "item.el" is a target DOM element (if present)
              // "item.src" is a source that you may modify
          
              //console.log('Parsing content. Item object that is being parsed:', item);
              onlineBooking.serviceId = item.el[0].id;
              onlineBooking.markSelectedServ();
            //   console.log(item.el[0].id)
            },
		}
    });

    // $('.popupBookForm').on('mfpOpen', function(e /*, params */) {
    //     var btn = $(e.target);
    //     let id = e.target.id;
    //     console.log(e.target)
    //     //console.log('Popup opened',  $.magnificPopup.instance);
    // });
}

function execContactsForm() {
    new CheckForms($('#feedForm'), '.button_submit');
}

/********************************
******** END Form Valid *********
********************************/

function initMap() {
    var mapCenter = $('meta[name="contacts:map-center"]').attr('content');

    ymaps.ready(function () {
        var myMap = new ymaps.Map('map', {
            center: mapCenter.split(','),
            zoom: 16,
            controls: ['fullscreenControl', 'zoomControl', 'geolocationControl']
        });
        myMap.behaviors.disable(['scrollZoom', 'rightMouseButtonMagnifier', 'multiTouch']);

    	for (var key in places) {

    		placesExec = new ymaps.Placemark(
    		    places[key],
    		    {
    		        balloonContentHeader: '<div class="mcapt">' + places[key][2] + '</div>',
    		        balloonContentBody: '<div class="mimage">' + places[key][3] + '</div>'
    		        // balloonContentFooter: '<div class="mbm">' + places[key][2] + '</div>'

    		    },
    		    {
                    preset: 'islands#blueAutoIcon'/*, 
                    iconColor: '#f28465'*/
    		    }
    		);
    		myMap.geoObjects.add(placesExec);
    	}
    });
}

function popupGal() {
    var options = {
        delegate: 'a',
        type: 'image',
        tLoading: 'Загружено #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            tCounter: '<span class="mfp-counter">%curr% из %total%</span>', // markup of counter
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">Изображение #%curr%</a> не может быть загружено.',
            titleSrc: function(item) {
                return item.el.attr('title') + '<small></small>';
            }
        }
    };
    $('.popup-gallery').magnificPopup(options);
}

function priceList() {
    $('.priceList').magnificPopup({
        type: 'iframe'
    });
}

function execShop () {
    if ($('meta[name="cart-url"]').length) shop();
}

x.exe['option-default'] = ['menuToggle()', 'placeholder()', 'onlineBooking()',/*'popupBookForm()',*/ 'execShop()', 'search()', 'priceList()'];
x.exe['option-index'] = ['sendMsg()', 'scrollToForm()', 'slider()', 'popupGal()'];
x.exe['option-contacts'] = ['initMap()', 'execContactsForm()', 'sendMsg()'];
x.exe['option-content'] = ['popupGal()']
x.exe['option-catalog-item'] = ['catalogItemGallery()', 'accordion()', 'carousel()']